/* eslint-disable react/display-name */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Redirect, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import LoadingSpinner from 'components/LoadingSpinner';
import BlockedEcosystem from '../../components/BlockedEcosystem';
import ExpiryWarning from '../../components/ExpiryWarning';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar';
import WedgeModal from '../../components/WedgeModal';

import { ECOSYSTEM_STATUS, CONSTANTS, ROUTES } from '../../enum';
import { loadEcosystem, refreshEcosystemStatus } from './scenario-actions';
import { resetRequestID } from '../../store/ecosystems/actions';
import { loading } from '../../store/global/actions';
import CommitFinishModal from '../../Modals/CommitFinishModal';

const BlockedStatus = [
  ECOSYSTEM_STATUS.COMMIT_IN_PROGRESS,
  ECOSYSTEM_STATUS.COMMIT_ERROR,
  ECOSYSTEM_STATUS.DISABLED,
  ECOSYSTEM_STATUS.ECOSYSTEM_ERROR,
  CONSTANTS.INACCESSIBLE,
];

const LoadableReports = Loadable({
  loader: () => import('../../views/Reports/Reports'),
  loading: () => <LoadingSpinner />,
});

const LoadableTraffic = Loadable({
  loader: () => import('../../views/Reports/Traffic'),
  loading: () => <LoadingSpinner />,
});

const LoadableGateways = Loadable({
  loader: () => import('../../views/Reports/Gateways'),
  loading: () => <LoadingSpinner />,
});

const LoadableUsersAndThings = Loadable({
  loader: () => import('../../views/Reports/UsersAndThings'),
  loading: () => <LoadingSpinner />,
});

const LoadableLogSettings = Loadable({
  loader: () => import('../../views/Reports/LogSetting'),
  loading: () => <LoadingSpinner />,
});

const LodableGuidedTasks = Loadable({
  loader: () => import('../../views/GuidedTasks'),
  loading: () => <LoadingSpinner />,
});

const LoadableObjects = Loadable({
  loader: () => import('../../views/Objects/Objects'),
  loading: () => <LoadingSpinner />,
});

const LoadableSecurity = Loadable({
  loader: () => import('../../views/Security/Security'),
  loading: () => <LoadingSpinner />,
});

const LodableSecurityProfile = Loadable({
  loader: () => import('../../views/SecurityProfile'),
  loading: () => <LoadingSpinner />,
});

const LodableDecryptionProfile = Loadable({
  loader: () => import('../../views/DecryptionProfiles'),
  loading: () => <LoadingSpinner />,
});

const LoadableAddressTranslation = Loadable({
  loader: () => import('../../views/AddressTranslation/AddressTranslation'),
  loading: () => <LoadingSpinner />,
});

const LoadableProfile = Loadable({
  loader: () => import('../../views/Profiles'),
  loading: () => <LoadingSpinner />,
});

const LodableAdminAccess = Loadable({
  loader: () => import('../../views/Settings/AdminAccess'),
  loading: () => <LoadingSpinner />,
});

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      openSuccess: false,
      openFailed: false,
      action: '',
    };
  }

  async componentDidMount() {
    const {
      location: { pathname },
      match: { params },
      loadEcosystem,
      refreshEcosystemStatus,
    } = this.props;
    const splittedPathname = pathname.split('/');
    const ecosystemURL = splittedPathname.slice(0, 6).join('/');
    if (splittedPathname.length === 6 || (splittedPathname.length === 7 && splittedPathname[6] === '')) {
      this.props.history.replace(`${ecosystemURL}/objects`);
    }
    const { ecoID: ecosystemUUID, cusID: customerUUID } = params;
    this.props.loading(true);
    await loadEcosystem({ customerUUID, ecosystemUUID });
    const { ecosystem } = this.props;
    if (
      ecosystem &&
      ![
        ECOSYSTEM_STATUS.COMMIT_ERROR,
        ECOSYSTEM_STATUS.DISABLED,
        ECOSYSTEM_STATUS.ECOSYSTEM_ERROR,
        CONSTANTS.INACCESSIBLE,
      ].includes(ecosystem.status)
    ) {
      await refreshEcosystemStatus({ customerUUID, ecosystemUUID });
      this.interval = setInterval(() => {
        refreshEcosystemStatus({ customerUUID, ecosystemUUID });
      }, 5000);
      this.props.loading(false);
      this.setState({ loaded: true });
    } else {
      const customersURL = splittedPathname.slice(0, 5).join('/');
      this.props.loading(false);
      this.props.history.replace(customersURL);
    }
  }

  static getDerivedStateFromProps(props) {
    if (!props.ecosystem) {
      return {
        status: CONSTANTS.INACCESSIBLE,
      };
    }
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.openSuccess !== this.state.openSuccess || nextState.openFailed !== this.state.openFailed) {
      return true;
    }
    if (this.state.loaded && this.props.ecosystem !== nextProps.ecosystem) {
      return this.props.ecosystem.status !== nextProps.ecosystem.status;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (
      window.innerWidth < 993 &&
      prevProps.history.location.pathname !== prevProps.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
      const ele = document.getElementById('bodyClick');
      if (ele) {
        ele.parentElement.removeChild(ele);
        const wrapper = document.getElementById('root').getElementsByClassName('wrapper')[0];
        wrapper.style.overflow = 'auto';
        const navbarElement = document.getElementsByClassName('navbar-collapse collapse in');
        if (navbarElement.length) {
          navbarElement[0].classList.toggle('in');
        }
      }
    }
    if (prevProps.history.action === 'PUSH') {
      if (document.documentElement) {
        document.documentElement.scrollTop = 0;
      }
      if (document.scrollingElement) {
        document.scrollingElement.scrollTop = 0;
      }
    }
    if (this.props.ecosystem) {
      const { status: ecosystemStatus, commitID, revertID } = this.props.ecosystem;
      if (
        ecosystemStatus !== ECOSYSTEM_STATUS.PENDING_CHANGES &&
        ecosystemStatus !== prevProps.ecosystem?.status &&
        (commitID || revertID)
      ) {
        this.setState({
          action: commitID ? 'Commit' : 'Revert',
        });
        if (ecosystemStatus === ECOSYSTEM_STATUS.OK) {
          this.setState({ openSuccess: true });
        } else if (ecosystemStatus === ECOSYSTEM_STATUS.COMMIT_ERROR) {
          this.setState({ openFailed: true });
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    document.documentElement.classList.toggle('nav-open');
    const ele = document.getElementById('bodyClick');
    if (ele) {
      ele.parentElement.removeChild(ele);
      const wrapper = document.getElementById('root').getElementsByClassName('wrapper')[0];
      wrapper.style.overflow = 'auto';
    }
  }

  closeCommitModal = () => {
    const {
      resetRequestID,
      match: { params },
    } = this.props;
    resetRequestID({ ecoId: params.ecoID });

    this.setState({ openSuccess: false, openFailed: false });
  };

  renderMainPanel = () => {
    const { loaded } = this.state;
    const { ecosystem } = this.props;
    const blocked = BlockedStatus.includes(ecosystem?.status);
    if (blocked) {
      return <BlockedEcosystem status={ecosystem.status} />;
    }
    if (loaded) {
      return (
        <Switch>
          <Route path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID/guided-tasks`} component={LodableGuidedTasks} />
          <Route path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID/objects`} component={LoadableObjects} />
          <Route
            path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID/security/web-filter`}
            component={LodableSecurityProfile}
          />
          <Route
            path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID/security/decryption`}
            component={LodableDecryptionProfile}
          />
          <Route path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID/security`} component={LoadableSecurity} />
          <Route
            path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID/addresstranslations`}
            component={LoadableAddressTranslation}
          />
          <Route path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID/logs/traffic`} component={LoadableTraffic} />
          <Route path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID/logs/gateway`} component={LoadableGateways} />
          <Route
            path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID/logs/usersandthings`}
            component={LoadableUsersAndThings}
          />
          <Route path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID/logs/settings`} component={LoadableLogSettings} />
          <Route path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID/logs`} component={LoadableReports} />
          <Route path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID/profile`} component={LoadableProfile} />
          <Route
            path={`${ROUTES.CUSTOMERS}/:cusID/ecosystems/:ecoID/settings/admin-access`}
            component={LodableAdminAccess}
          />
          <Redirect to={ROUTES.NOT_FOUND} />
        </Switch>
      );
    }
    return null;
  };

  render() {
    const { openSuccess, openFailed, action } = this.state;
    const { ecosystem } = this.props;
    const { commitID, revertID } = ecosystem || {};

    return (
      <div className="wrapper">
        <ExpiryWarning />
        <Sidebar {...this.props} />
        <div id="main-panel" className="main-panel">
          <Header {...this.props} dashboardHeader />
          {this.renderMainPanel()}
          <Footer />
        </div>
        <WedgeModal
          isOpen={openSuccess || openFailed}
          onClose={this.closeCommitModal}
          title={openSuccess ? `${action} Success!` : `${action} Refused!`}
          size="small"
        >
          <CommitFinishModal
            ecosystem={ecosystem}
            success={openSuccess}
            onClose={this.closeCommitModal}
            requestID={action === 'Commit' ? commitID : revertID}
            action={action}
          />
        </WedgeModal>
      </div>
    );
  }
}

Dashboard.propTypes = {
  match: PropTypes.object.isRequired,
  loadEcosystem: PropTypes.func.isRequired,
  refreshEcosystemStatus: PropTypes.func.isRequired,
  resetRequestID: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loading: PropTypes.func.isRequired,
  ecosystem: PropTypes.object,
};

const mapStateToProps = (state) => ({
  ecosystem: state.ecosystems.currentEcosystem,
});

const mapDispatchToProps = (dispatch) => ({
  loading: (bool) => dispatch(loading(bool)),
  resetRequestID: ({ ecoId }) => dispatch(resetRequestID({ ecoId })),
  loadEcosystem: ({ ecosystemUUID, customerUUID }) => dispatch(loadEcosystem({ ecosystemUUID, customerUUID })),
  refreshEcosystemStatus: ({ ecosystemUUID, customerUUID }) =>
    dispatch(refreshEcosystemStatus({ ecosystemUUID, customerUUID })),
});

const ConnectedDashboardLayout = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export default withRouter(ConnectedDashboardLayout);
